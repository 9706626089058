import { IconButton, Tooltip, Typography } from '@mui/material'
import InfoOverIcon from '_icons/info_over-sm.svg?react'
import DownloadIcon from '_icons/project-menu/download.svg?react'
import ValueLabel from 'components/Common/ValueLabel/ValueLabel'
import { WorkingStepIconSvg } from 'components/Common/WorkingSteps/WorkingStepIcon'
import { useLocalizedWorkingStepLabel } from 'components/Localization/useLocalizedWorkingStepLabel'
import { useWorkingStepFilter } from 'features/BillOfMaterials/BoMItemFilter/Components/WorkingStepsFilter/useWorkingStepFilter'
import { t } from 'i18next'
import { MoneyString } from 'model/Money'
import { MoneyPerQuantityString } from 'model/MoneyPerQuantity'
import { BomItemPointer } from 'model/Project/BomItemPointer'
import { QuantityString } from 'model/Quantity'
import {
  ManuallySetProductionTimeArgs,
  PriceRowDto,
  PriceScope,
  WorkingStepType,
} from 'services/APIs/InternalAPI/internal-api.contracts'
import { APICall, ErrorMessage } from 'store/Application/appActions'
import store from 'store/configureStore'
import { StoreUtils } from 'store/storeUtils'
import { AdditionalProdCostsInput } from '../Inputs/AdditionalProdCostsInput'
import { ProdTimeInput } from '../Inputs/ProdTimeInput'
import {
  FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
  useFinancialDetailsFormStyles,
} from './FinancialDetailsForm.styles'

type Props = {
  bomItemPointer: BomItemPointer
  workingStepPricingRow: PriceRowDto
  setActiveCostPriceExplanation: (priceRow: PriceRowDto) => void
  priceScope: PriceScope
  handleResetAdditionalCosts: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) => Promise<void>
  handleResetProductionTime: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto
  ) => Promise<void>
  handleSaveProductionTime: (
    bomItemPointer: BomItemPointer,
    priceRow: ManuallySetProductionTimeArgs
  ) => Promise<void>
  handleSaveAdditionalCostPrice: (
    bomItemPointer: BomItemPointer,
    priceRow: PriceRowDto,
    priceScope: PriceScope
  ) => Promise<void>
  loading: Record<string, boolean>
}

const downloadCostDriverCalculators = (
  bomItemPointer: BomItemPointer,
  primaryWorkingStep: WorkingStepType,
  secondaryWorkingStep: WorkingStepType,
  resourceId: string
) => {
  const { partyId, projectId } = StoreUtils.GetContext()

  store.dispatch(
    APICall({
      label: `downloadCostDriverCalculators_${bomItemPointer.id}_${primaryWorkingStep}_${secondaryWorkingStep}_${resourceId}`,
      configuration: {
        method: 'GET',
        url: `api/parties/${partyId}/projects/${projectId}/items/${bomItemPointer.id}/workingSteps/costDriverCalculators?primaryWorkingStep=${primaryWorkingStep}&secondaryWorkingStep=${secondaryWorkingStep}&resourceId=${resourceId}`,
        isFileDownload: true,
        onProgress: (progress) =>
          console.info('downloadCostDriverCalculators -> progress:', progress),
        onError: (message: string) => {
          ErrorMessage('cost drivers', message)
        },
      },
    })
  )
}

export function PriceDetailsWorkingStepRow(props: Props) {
  const { classes, cx } = useFinancialDetailsFormStyles()
  const getLocalizedWorkingStep = useLocalizedWorkingStepLabel()

  const { workingStepFilter } = useWorkingStepFilter()

  if (
    workingStepFilter.active &&
    workingStepFilter.filter.enabledFilters.length
  ) {
    const filterAndAdditionalWorkingSteps = [
      ...workingStepFilter.filter.enabledFilters,
      WorkingStepType.Setup,
      WorkingStepType.Loading,
      WorkingStepType.Unloading,
    ]

    if (
      !filterAndAdditionalWorkingSteps.some((f) => {
        return (
          props.workingStepPricingRow.workingStep.secondaryWorkingStep === f
        )
      })
    ) {
      return null
    }
  }

  return (
    <li
      className={cx(classes.row, {
        [classes.sharedCosts]: props.workingStepPricingRow.costsAreShared,
      })}
    >
      <div className={cx(classes.header)}>
        <WorkingStepIconSvg
          workingStepType={
            props.workingStepPricingRow.workingStep.secondaryWorkingStep
          }
          attributes={{
            width: '24px',
          }}
        />
        <div className={classes.resourceLabel}>
          {/* <Typography variant="body1" component="p">
            {props.workingStepPricingRow.workingStep.resource?.name}
          </Typography> */}
          <div className={classes.resourceDescription}>
            <Typography variant="body2" component="p">
              {getLocalizedWorkingStep(
                props.workingStepPricingRow.workingStep.isPrimaryWorkingStep
                  ? props.workingStepPricingRow.workingStep.primaryWorkingStep
                  : props.workingStepPricingRow.workingStep.secondaryWorkingStep
              )}
              {props.workingStepPricingRow.costsAreShared &&
                ` - ${t('project:costs-are-shared')}`}
            </Typography>
            <div style={{ alignSelf: 'flex-start' }}>
              {props.workingStepPricingRow.isBatchIndependent ? (
                <Tooltip
                  placement="top"
                  arrow
                  title={t(
                    'common:estimation-batch-independent',
                    'estimation and additional costs are quantity independent'
                  )}
                >
                  <span>
                    <InfoOverIcon width={16} />
                  </span>
                </Tooltip>
              ) : null}
            </div>
            <Typography variant="caption" color="textSecondary" component="p">
              {MoneyPerQuantityString(props.workingStepPricingRow.rate)}
            </Typography>
          </div>
        </div>
        <IconButton
          size="small"
          onClick={() =>
            downloadCostDriverCalculators(
              props.bomItemPointer,
              props.workingStepPricingRow.workingStep.primaryWorkingStep,
              props.workingStepPricingRow.workingStep.secondaryWorkingStep,
              props.workingStepPricingRow.resourceId
            )
          }
        >
          <DownloadIcon width={'21px'} />
        </IconButton>
      </div>
      <div className={classes.values}>
        <ProdTimeInput
          bomItemPointer={props.bomItemPointer}
          priceRow={props.workingStepPricingRow}
          priceScope={props.priceScope}
          handleResetProductionTime={props.handleResetProductionTime}
          handleSaveManuallySetProductionTime={props.handleSaveProductionTime}
          loading={props.loading}
        />
        <Tooltip
          placement="top"
          title={
            <>{`${t('project:costs-prod')}: ${MoneyString(
              props.workingStepPricingRow.productionTimeCosts,
              10
            )}`}</>
          }
        >
          <span style={{ textAlign: 'right' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              value={MoneyString(
                props.workingStepPricingRow?.productionTimeCosts
              )}
            />
          </span>
        </Tooltip>
        <AdditionalProdCostsInput
          bomItemPointer={props.bomItemPointer}
          priceRow={props.workingStepPricingRow}
          priceScope={props.priceScope}
          handleResetAdditionalCosts={props.handleResetAdditionalCosts}
          handleManualSaveAdditionalCosts={props.handleSaveAdditionalCostPrice}
          loading={props.loading}
          defaultMoneyObject={props.workingStepPricingRow.costPrice}
        />
        <Tooltip
          placement="top"
          title={
            <>{`${t('project:costs-total')}: ${MoneyString(
              props.workingStepPricingRow.costPrice,
              10
            )}`}</>
          }
        >
          <span style={{ textAlign: 'right', position: 'relative' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              value={MoneyString(props.workingStepPricingRow?.costPrice)}
            />
          </span>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            <>
              {`${t('common:surcharge')}: ${MoneyString(
                props.workingStepPricingRow.surchargeValue,
                10
              )}`}
              <Typography
                color="textSecondary"
                variant="caption"
                style={{
                  position: 'absolute',
                  bottom: '-1.25em',
                  right: 0,
                }}
              >
                {QuantityString(props.workingStepPricingRow.surchargeRatio)}
              </Typography>
            </>
          }
        >
          <span style={{ textAlign: 'right', position: 'relative' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              value={MoneyString(props.workingStepPricingRow?.surchargeValue)}
            />

            <Typography
              color="textSecondary"
              variant="caption"
              component="div"
              style={{
                position: 'absolute',
                right: 0,
                bottom: '-1.25em',
              }}
            >
              {QuantityString(props.workingStepPricingRow?.surchargeRatio)}
            </Typography>
          </span>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            <>{`${t('common:discount')}: ${MoneyString(
              props.workingStepPricingRow.discountValue,
              10
            )}`}</>
          }
        >
          <span style={{ textAlign: 'right', position: 'relative' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              // label={'discount/discount'}
              value={MoneyString(props.workingStepPricingRow?.discountValue)}
            />
            <Typography
              color="textSecondary"
              variant="caption"
              component="div"
              style={{
                position: 'absolute',
                right: 0,
                bottom: '-1.25em',
              }}
            >
              {QuantityString(props.workingStepPricingRow?.discountRatio)}
            </Typography>
          </span>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            <>{`${t('project:sales-price')}: ${MoneyString(
              props.workingStepPricingRow.salesPrice,
              10
            )}`}</>
          }
        >
          <span style={{ textAlign: 'right' }}>
            <ValueLabel
              valueLabelRootStyles={{
                width: FINANCIAL_DETAILS_VALUE_LABEL_WIDTH,
              }}
              valueProps={{
                variant: 'body2',
              }}
              // label={'sales price'}
              value={MoneyString(props.workingStepPricingRow?.salesPrice)}
            />
          </span>
        </Tooltip>
      </div>
    </li>
  )
}
